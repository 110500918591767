import React from 'react'
import styles from './article.module.css'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Img from 'gatsby-image'

export default ({ article }) => (
  <div className={styles.link} onClick={() => window.open(article.downloadLink)}>
  <Card className={styles.root}>
  <CardHeader
    avatar={
      <Avatar alt={article.author.name} src={article.author.image.fluid.src} className={styles.avatar}></Avatar>
    }    
    title={article.title}
    subheader={article.author.name+ ": " + article.publishDate}
  />
  <Img alt="" fluid={article.heroImage.fluid} />
  <CardContent>
     <p>{article.description.childMarkdownRemark.html.replace(/<p>/g,"").replace(/<\/p>/g,"").replace(/<ol>/g,"").replace(/<\/ol>/g,"").replace(/<li>/g,"").replace(/<\/li>/g,"")}</p>
     <p>(Klik om meer te lezen)</p>
  </CardContent>  
  </Card>
  </div> 
)
