import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import ArticlePreview from '../components/article'
import { FaMailBulk } from 'react-icons/fa';
import navStyles from '../components/navigation.module.css';
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Logo from '../components/logo'

class Nieuwsbrief extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const geskrijven = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Logo author={author} />
        <div className="wrapper">
        <ul className="header-list">
          <li><div className={navStyles.iconimage}><FaMailBulk  className={navStyles.icon}/></div></li>
            <h2><u>N</u>ieuwsbrieven</h2>          
          </ul>
          <hr className="hr-repeat"></hr>
          <br/>
          <ul className="article-list">
            {geskrijven.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default Nieuwsbrief

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
            ) {
             ...GatsbyContentfulFluid
            }
          }
          downloadLink
          description {
            childMarkdownRemark {
              html
            }
          }
          author{image{
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
             ...GatsbyContentfulFluid
              }            
          },
            name
          }
        }
      }
    }
    allContentfulPerson(filter: { id: { eq: "e339d456-b3e8-5213-8d99-cf8ebb14a0a0" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          headline
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }    
`
